







































import { Component, Vue } from "vue-property-decorator";
import SettingsBaseView from "@/views/account/settings/SettingsBaseView.vue";
import AccountSettingsDataContext from "@/dataContexts/AccountSettingsDataContext";
import { ValidationObserver } from "vee-validate";
import Notification from "@/common/Notification";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import AccountErrors from "@/common/AccountErrors";
import { InputComponent } from "keiryo";
import { UserSessionManager } from "kogitte";
import PasswordNotice from "@/components/global/PasswordNotice.vue";
import Router from "@/router/Router";
import Routes from "@/router/Routes";

@Component({
    components: {
        SettingsBaseView,
        InputComponent,
        PasswordNotice
    }
})
export default class ChangePasswordView extends Vue {
    private userSessionManager = new UserSessionManager();
    private accountSettingsDataContext: AccountSettingsDataContext = new AccountSettingsDataContext();

    private loading = false;

    private password = "";
    private currentPassword = "";

    private async onSubmit() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        if (await observer.validate()) {
            this.loading = true;

            const saveResult = await this.accountSettingsDataContext.changePassword(this.password, this.currentPassword);

            if (saveResult.successfully) {
                await this.userSessionManager.logout();
                Router.navigate(Routes.Login);

                Notification.addSuccess(TranslationUtils.translate("notification.savedSuccessfully")).show();
            } else {
                if (saveResult.data) {
                    let errorContentKey: string|undefined;

                    switch (saveResult.data) {
                        case AccountErrors.WrongPassword:
                            errorContentKey = "view.settings.changePassword.wrongPassword";
                            break;

                        case AccountErrors.InvalidToken:
                            errorContentKey = "view.settings.changePassword.invalidToken";
                            break;

                        default:
                            break;
                    }

                    if (errorContentKey) {
                        Notification.addError(TranslationUtils.translate(errorContentKey), false).show();
                    }
                }
            }

            this.loading = false;
        }
    }
}
